import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { makeAutoObservable } from 'mobx'

type Services = {
  loadHealthInsurancePlans: LoadHealthInsurancePlans
}

export class HealthInsuranceService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadHealthInsurancePlans(
    params: LoadHealthInsurancePlans.Params
  ): Promise<LoadHealthInsurancePlans.Model> {
    return this.services.loadHealthInsurancePlans.load(params)
  }
}

export default HealthInsuranceService
