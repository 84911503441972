import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import HealthInsuranceService from 'presentation/contexts/services/health-insurance-service'
import { ApiRepository } from 'repository/api-repository'
import { HealthInsuranceRepository } from 'repository/repositories/health-insurance/health-insurance-repository'
import { RemoteLoadHealthInsurancePlans } from 'service/usecases/load-health-insurance-plans/remote-load-health-insurance-plans'

export default function makeHealthInsuranceService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const healthInsuranceRepository = new HealthInsuranceRepository(apiRepository)

  const loadHealthInsurancePlans = new RemoteLoadHealthInsurancePlans(
    healthInsuranceRepository
  )

  return new HealthInsuranceService({
    loadHealthInsurancePlans
  })
}
